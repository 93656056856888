import { createEvent as serviceCreateEvent, ValidationError } from "@/services/event/create-event";
export function useCreateEvent({ eventData, setErrors, onSuccess }) {
    const createEvent = async () => {
        if (eventData.value === null)
            return;
        const result = await serviceCreateEvent.run(eventData.value);
        if (result.isOk()) {
            onSuccess && await onSuccess(result.value);
        }
        if (result.isFail() && result.value instanceof ValidationError) {
            const errors = result.value.getErrors();
            setErrors(errors);
            return;
        }
        if (result.isFail()) {
            debugger;
        }
    };
    return {
        eventCreating: serviceCreateEvent.active,
        createEvent,
    };
}
