import { defineComponent } from "@vue/composition-api";
import EditableEvent from "@events/components/EditableEvent/index.vue";
import { useValidationErrors } from "@/composable/validation-errors";
import { useEditingEventData } from "@events/composable/event-data";
import { useEditingEventFields } from "@events/composable/event-fields";
import { useCreateEvent } from "./create-event";
import router from "@/router";
export default defineComponent({
    name: "CreateEvent",
    components: {
        EditableEvent,
    },
    setup(props, vm) {
        const { errors: eventErrors, setErrors, clearErrors } = useValidationErrors();
        const requiredFields = [
            "name",
            "type",
            "zone",
            "creationTime",
            "leaders",
            "involvedRescuers",
        ];
        const visibleFields = [
            "type",
            "name",
            "zone",
            "coordinate",
            "creationTime",
            "activationTime",
            "creator",
            "victims",
            "owners",
            "leaders",
            "description",
            "involvedRescuers",
            "involvedVehicles",
        ];
        const { eventData, dataFulfilled, updateEventData } = useEditingEventData({ requiredFields });
        const { eventFields, handleEventFieldUpdate, handleCustomFieldUpdate, } = useEditingEventFields({ clearErrors, visibleFields });
        const { createEvent, eventCreating } = useCreateEvent({
            setErrors, eventData,
            onSuccess: async (event) => {
                vm.root.$toast("Подію створено!");
                await router.push(`/events/active/${event.id}`);
            }
        });
        return {
            eventData,
            eventFields,
            eventRequiredFields: requiredFields,
            dataFulfilled,
            updateEventData,
            handleEventFieldUpdate,
            handleCustomFieldUpdate,
            createEvent,
            eventErrors,
            eventCreating,
        };
    },
});
