import { Event } from "@/models/Event";
import { TransformValidationError, TransformUnknownError, flatErrorArray } from "@/services/transform";
import { ApiValidationError } from "@/services/api";
import { fail, success } from "@/utils/either";
import { createEvent } from "./api";
export async function createAndTransformEvent(eventData) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    try {
        const result = await createEvent({
            name: eventData.name,
            type: eventData.type,
            zone: eventData.zone,
            coordinates: {
                latitude: eventData.coordinate.latitude,
                longitude: eventData.coordinate.longitude,
            },
            time: eventData.creationTime,
            activated: eventData.activationTime,
            creator: eventData.creator,
            owners: eventData.owners,
            leaders: eventData.leaders,
            victims: eventData.victims,
            description: eventData.description,
            involved: {
                rescuers: eventData.involvedRescuers,
                vehicles: eventData.involvedVehicles,
            },
        });
        if (result.isOk()) {
            return success(new Event(result.value));
        }
        else {
            if (result.value instanceof ApiValidationError) {
                const validationErrorsTmpl = await result.value.getObjectErrors();
                const transformedErrors = new TransformValidationError({
                    name: (_a = validationErrorsTmpl.name) !== null && _a !== void 0 ? _a : [],
                    type: (_b = validationErrorsTmpl.type) !== null && _b !== void 0 ? _b : [],
                    zone: (_c = validationErrorsTmpl.zone) !== null && _c !== void 0 ? _c : [],
                    coordinate: (_d = validationErrorsTmpl.coordinates) !== null && _d !== void 0 ? _d : [],
                    creationTime: (_e = validationErrorsTmpl.time) !== null && _e !== void 0 ? _e : [],
                    activationTime: (_f = validationErrorsTmpl.activated) !== null && _f !== void 0 ? _f : [],
                    creator: (_g = validationErrorsTmpl.creator) !== null && _g !== void 0 ? _g : [],
                    owners: validationErrorsTmpl.owners === undefined ? [] : flatErrorArray(validationErrorsTmpl.owners),
                    leaders: validationErrorsTmpl.leaders === undefined ? [] : flatErrorArray(validationErrorsTmpl.leaders),
                    victims: (_h = validationErrorsTmpl.victims) !== null && _h !== void 0 ? _h : [],
                    description: (_j = validationErrorsTmpl.description) !== null && _j !== void 0 ? _j : [],
                    involvedRescuers: (_l = (_k = validationErrorsTmpl.involved) === null || _k === void 0 ? void 0 : _k[0].rescuers) !== null && _l !== void 0 ? _l : [],
                    involvedVehicles: (_o = (_m = validationErrorsTmpl.involved) === null || _m === void 0 ? void 0 : _m[0].vehicles) !== null && _o !== void 0 ? _o : [],
                });
                return fail(transformedErrors);
            }
            else {
                return fail(result.value);
            }
        }
    }
    catch (e) {
        return fail(new TransformUnknownError(e));
    }
}
