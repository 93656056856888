import { request, ApiValidationError, ApiServerError, ApiUnknownError } from "@/services/api";
import { mapResponse } from "@/utils/map-checkable-response";
import { fail } from "@/utils/either";
export async function createEvent(data) {
    try {
        const result = await request.checkablePost("/events", data);
        return mapResponse(result, {
            400: ApiValidationError,
            500: ApiServerError,
        });
    }
    catch (e) {
        return fail(new ApiUnknownError(e));
    }
}
